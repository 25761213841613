import React from 'react'
import bg from '../../../images/35.jpg'
import one from '../../../images/one.png'
import two from '../../../images/two.png'
import three from '../../../images/three.png'
import four from '../../../images/four.png'
import five from '../../../images/five.png'
import six from '../../../images/six.png'


const Third_pane = () => {
  return (
    <div className='uk-margin-large-top'>
        <div className='uk-grid uk-visible@s'
        
        style={{ 
    
            backgroundImage: ` url(${bg})`,
        
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '100px',
            backgroundAttachment:'fixed',
            height: '80vh'
        
        }}
        
        
        
        
        >
            <div className='uk-width-4-5' >
                  <div className='uk-container  ammenitiesContainer uk-margin-xlarge-left'> 
                   <h2>AMMENITIES</h2> 




                      <div className='uk-grid'>

                        <div className='uk-width-1-3@s'>
                        <div>
                            <img src= {one} />
                            <h4 className=" goldText" style={{marginTop:'0'}}>Smart Home</h4>
                        </div>
                        </div>


                        <div className='uk-width-1-3@s'>
                        <div>
                            <img src= {two} />
                            <h4 className="goldText" style={{marginTop:'0'}}>Air Conditioner</h4>
                        </div>
                        </div>



                        <div className='uk-width-1-3@s'>
                        <div>
                            <img src= {three} />
                            <h4 className=" goldText" style={{marginTop:'0'}}>Jacuzzi</h4>
                        </div>
                        </div>


                      </div>


                      {/***SECOND ROW***/}
                      
                      <div className='uk-grid'>

                        <div className='uk-width-1-3@s'>
                        <div>
                            <img src= {four} />
                            <h4 className=" goldText" style={{marginTop:'0'}}>Garage</h4>
                        </div>
                        </div>


                        <div className='uk-width-1-3@s'>
                        <div>
                            <img src= {five} />
                            <h4 className="goldText" style={{marginTop:'0'}}>Elevator</h4>
                        </div>
                        </div>



                        <div className='uk-width-1-3@s'>
                        <div>
                            <img src= {six} />
                            <h4 className=" goldText" style={{marginTop:'0'}}>Fully Furnished</h4>
                        </div>
                        </div>


                      </div>


                   </div>

            </div>

            <div className='uk-width-1-5'>

            </div>
        </div>

                    

                    {/***MOBILE* */}

                    <div className='uk-grid uk-hidden@s'
        
        style={{ 
    
            backgroundImage: ` url(${bg})`,
        
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '100px',
            backgroundAttachment:'fixed',
            height: '140vh'
        
        }}
        
        
        
        
        >
            <div className='uk-width-4-5' >
                  <div className='uk-container ammenitiesContainer uk-margin-xlarge-left'> 
                   <h2>AMMENITIES</h2> 




                      <div className='uk-grid'>

                        <div className='uk-width-1-3@s'>
                        <div>
                            <img src= {one} />
                            <h4 className=" goldText" style={{marginTop:'0'}}>Smart Home</h4>
                        </div>
                        </div>


                        <div className='uk-width-1-3@s'>
                        <div>
                            <img src= {two} />
                            <h4 className="goldText" style={{marginTop:'0'}}>Air Conditioner</h4>
                        </div>
                        </div>



                        <div className='uk-width-1-3@s'>
                        <div>
                            <img src= {three} />
                            <h4 className=" goldText" style={{marginTop:'0'}}>Jacuzzi</h4>
                        </div>
                        </div>


                      </div>


                      {/***SECOND ROW***/}
                      
                      <div className='uk-grid'>

                        <div className='uk-width-1-3@s'>
                        <div>
                            <img src= {four} />
                            <h4 className=" goldText" style={{marginTop:'0'}}>Garage</h4>
                        </div>
                        </div>


                        <div className='uk-width-1-3@s'>
                        <div>
                            <img src= {five} />
                            <h4 className="goldText" style={{marginTop:'0'}}>Elevator</h4>
                        </div>
                        </div>



                        <div className='uk-width-1-3@s'>
                        <div>
                            <img src= {six} />
                            <h4 className=" goldText" style={{marginTop:'0'}}>Fully Furnished</h4>
                        </div>
                        </div>


                      </div>
        </div>

            </div>

            <div className='uk-width-1-5'>

            </div>
        </div>




















    </div>
  )
}

export default Third_pane