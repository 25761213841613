import React, { useRef } from 'react'

import emailjs from '@emailjs/browser';
import dotbg from '../../../images/dot-bg.png'
import contact_img from '../../../images/contact_img.jpg'


const Sixth_pane = () => {


          const form = useRef()


          const sendEmail = (e) => {
            e.preventDefault();
        
            emailjs.sendForm('service_4lwrm3e', 'template_ma7ii8w', form.current, 'SX0CPtK14-r61NbnF')
              .then((result) => {
                  console.log(result.text);
              }, (error) => {
                  console.log(error.text);
              });
          };



  return (
    <div>
        <div className='uk-container  uk-margin-large-top'
              style={{ 
    
                backgroundImage: ` url(${dotbg})`,
              
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                paddingBottom: '50px',
                height: 'auto'
             }}
        
         >

          <div className='uk-grid' data-uk-grid style={{backgroundColor:'#fff', marginTop:'-40px'}}>
                   <div className='uk-width-1-2@s' uk-scrollspy="cls: uk-animation-scale-up; repeat: true">
                     <h1 className='uk-padding uk-margin-right ' >
                      Let's sit and talk about your new home</h1>

                  


                      <form ref={form}  onSubmit={sendEmail}>
                              <div className='input-row'>
                                <div className='input-group'>
                                    <label>Name</label>
                                    <input type="text" name = "user_name"  placeholder='John Domelo' />
                                </div>

                                <div className='input-group'>
                                    <label>Phone</label>
                                    <input type="text" name = "phone_number" placeholder='+234 879 894' />
                                </div>
                            </div>


                            <div className='input-row'>
                                <div className='input-group'>
                                    <label>Email</label>
                                    <input type="email" name = "user_email" placeholder='ab_i@mail.com' />
                                </div>

                                <div className='input-group'>
                                    <label>Subject</label>
                                    <input type="text" name = "subject" placeholder='+234 879 894' />
                                </div>
                            </div>


                            <label>Message</label>
                            <textarea rows="5" name = "text-area" placeholder='Your message'></textarea>
                            <button type='submit'>SEND</button>
                            </form>






                   </div>

                   <div className='uk-width-1-2@s'>
                     <div
                     
                     style={{ 
    
                      backgroundImage: ` url(${contact_img})`,
                    
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      paddingBottom: '100px',
                      height: '70vh',
                      borderBottomRightRadius:'80px'
                   }}
                     
                   uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true"
                     
                     
                     >
                      </div>
                   </div>
          </div>



        
        </div>

    </div>
  )
}

export default Sixth_pane