import React from "react";
import footerBg from '../../images/footerBg.jpg'
import logo from '../../images/new_logo.png'
import { Link } from "react-router-dom";

const  FooterBlock= ()=> {

    return(
        <div

        style={{ 
    
            backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${footerBg})`,
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${footerBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '100px',
            height: 'auto',
            backgroundAttachment:'fixed',
        
        }}
         
         
         
         
         >
            <div className=" uk-container uk-margin-large-top">
           <div  className="uk-grid uk-margin-large-top" data-uk-grid>
                <div className="uk-width-1-4@s">
                    <div className=" uk-margin-medium-top ">
                         <img src={logo} className="foooterLogo"/>
                         <p className="uk-text-left">  Over the years, we have successfully carved a
                           niche as the best in building high end infrastructures and delivering
                            unparalleled experiences for our highly esteemed clients for sale and lease.</p>
                    </div>
                </div>

                <div className="uk-width-1-4@s">
                <div className=" uk-margin-medium-top">
                        <div><h6 className="light uk-text-left uk-text-bold">SITE MAP</h6></div>
                           
                        <div class="vertical-menu">
                            <Link to ="/" class="active">Home</Link>
                            <Link to ="/about"> About Us</Link>
                            <Link to ="/projectOne">Our Communities</Link>
                       
                            <Link to ="/contact">Contact</Link>
                     </div> 

                        
                    </div>
                </div>

                <div className="uk-width-1-4@s">
                <div className=" uk-margin-medium-top">
                        <div><h6 className="light uk-text-left uk-text-bold">RECENT PROJECTS</h6></div>
                        <div class="vertical-menu">
                            <a href="/projectOne" class="active">Adelaide Court</a>
                            <a href="/projectTwo"> Labella Casa</a>
                            <a href="/vaud"> Vaud Apartment</a>
                          
                     </div>
                    </div>
                </div>

                <div className="uk-width-1-4@s">
                <div className=" uk-margin-medium-top">
                        <div><h6 className="light uk-text-left uk-text-bold">CONTACT US</h6></div>
                        <div class="uk-flex">
                            <a href="" uk-icon="mail" className="icon uk-margin-right"></a>
                           <div><p>info@bolek.com.ng</p></div>
                     </div> 


                     <div class="uk-flex uk-margin-top">
                            <a href="" uk-icon="receiver" className="icon uk-margin-right"></a>
                           <div><p className="uk-text-left">0708 338 5164</p></div>
                     </div>


                      <div class="uk-flex uk-margin-top">
                            <a href="" uk-icon="home" className="icon uk-margin-right"></a>
                           <div><p  className="uk-text-left">8 Abdullahi Ibrahim Street, Utako Abuja</p></div>
                     </div> 
                    </div>
                </div>
           </div>
        </div>



        {/**Social Links**/}
        <div className="uk-container socialFooterDiv uk-margin-medium-top">
           <div className="uk-grid" data-uk-grid>
               <div className="uk-width-1-3@s">
               <div class="uk-flex">
                    <div class="uk-text-bold light">Follow Us</div>
                    <Link to ="" class="uk-icon-button uk-margin-medium-left  uk-margin-small-right" uk-icon="twitter"></Link>
                    <Link to ="http://facebook.com/profile.php?id=100095670335152" class="uk-icon-button  uk-margin-small-right" uk-icon="facebook"></Link>
                    <Link to ="" class="uk-icon-button" uk-icon="youtube"></Link>
                    <Link to ="" class="uk-icon-button uk-margin-small-left "  uk-icon="instagram"></Link>
              </div>
               </div>

               <div className="uk-width-1-3@s">
                 
               </div>

               <div className="uk-width-1-3@s">
               
               </div>
         </div>
          </div>

         <div className="footerBottom uk-margin-large-top">
                   <div className="uk-text-center">
                     <h4 className="light">Powered by Bolek Nig Ltd</h4>
                   </div>
               </div>



        </div>
    )
}


export default FooterBlock