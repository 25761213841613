import image1 from '../images/t-image1.jpg'
import image2 from '../images/t-image2.jpg'
import image3 from '../images/t-image3.jpg'



export const testimonialsData = [
  {
    image: image1,
    review:
      "They are very, very reliable, assessable, warm and inviting… they’re just always there to help you with the right choice in Real Estate investment,  answer all your questions, and be transparent … they do what they say and say what they do.",
    name: 'MATHEW Ikebundu',
    status : 'BUSINESS MAN-Abuja'
  },
  {
    image: image2,
    review: '“I have to say – I love the Bolek team – the culture, what they stand for and the way they do business – leaving things better for all. Thank you!”',
    name: 'Helen Bashir Ahmed',
    status: 'Entreprenuer'
  },
  {
    image : image3,
    review:' Just a quick note to thank the team for what they have done …You have renewed my faith in real estate investment after years of somewhat misguided results with hard earned money.  ',
    name: 'FRANKLIN',
    status: "Civil Servant"
  }
];
