import React from 'react'
import Layout from '../../Layout/Layout'
import Hero from './Blocks/Hero'
import First_Pane from './Blocks/First_Pane'
import Second_Pane from './Blocks/Second_Pane'
import Third_Pane from './Blocks/Third_Pane'
import Fourth_Pane from './Blocks/Fourth_Pane'
import Fifth_Pane from './Blocks/Fifth_Pane'
import Sixth_pane from './Blocks/Sixth_pane'
import Counter from './Blocks/Counter'

const Home = () => {
  return (
    <Layout>
      <Hero />
      <First_Pane />
      <Second_Pane />
      <Third_Pane />
      <Fourth_Pane />
       <Fifth_Pane />
      <Sixth_pane />
      
     
      
     
      
    </Layout>
  
  )
}

export default Home