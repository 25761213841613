
import './App.css';
import './mainstyle.css'

import { BrowserRouter, Routes,Route,  } from 'react-router-dom';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import ProjectOne from './Components/ProjectOne/ProjectOne';
import ProjectTwo from './Components/ProjectTwo/ProjectTwo';
import Vaud from './Components/Vaud/Vaud';
import Contact from './Components/Contact/Contact';

function App() {
  return (
    <BrowserRouter>
    <Routes>

      
    <Route  path="/" element={<Home />} />
    
    <Route  path="/about" element={<About />} />
    
    <Route  path="/projectOne" element={<ProjectOne />} />
    
    <Route  path="/ProjectTwo" element={<ProjectTwo />} />
    <Route  path="/vaud" element={<Vaud />} />
    
    <Route  path="/contact" element={<Contact />} />




      </Routes>
      </BrowserRouter>


  );
}

export default App;
