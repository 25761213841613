import React from 'react'
import boite from '../../../images/boite.jpg'

import index from '../../../images/index.jpeg'

import tiblon1 from '../../../images/tiblon1.jpg'

import { Link } from 'react-router-dom'

const Third_Pane = () => {
  return (
    <div>
      {/***DESKTOP***/}
      <div className='uk-visible@s'>
    <div className='uk-container'>
             <div className='home_third_pane'>
                    <h1 uk-scrollspy="cls: uk-animation-slide-down; repeat: true">Latest Communities</h1>
                    <p uk-scrollspy="cls: uk-animation-slide-top; repeat: true">We are delighted to present our latest Communities with 
                    cutting edge technology systems</p>
             </div>
    </div>
           

      <div className='uk-grid uk-grid-collapse uk-margin-large-top'>
            <div className='uk-width-2-5@s'>
                <div className='uk-text-right home_third_pane_comm uk-padding' style={{backgroundColor:'#1d4344',height:'74vh', paddingBottom:'120px'}}>

                  <div className='uk-margin-large-bottom'>  
                  <h2 uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Adelaide court</h2>
                    <p uk-scrollspy="cls: uk-animation-slide-left; repeat: true"> 
                    Each apartment is equipped with contemporary fixtures and fittings 
                    to create a  modern ambience for living.  These apartments Are comprised 
                    of spacious 8 Units of 3 Bedroom Apartments.</p>
                         </div>
                         
                         <Link ><a href="/projectOne" className='gold-btn uk-margin-right' >View  Project</a> </Link>
                       
                </div>

               
              

            </div>


            <div className='uk-width-3-5@s'>
                <div  className='forIpadImg'
                
                style={{ 
    
                    backgroundImage: ` url(${index})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                   
                    height:'74vh'
              }}
                
              uk-scrollspy="cls: uk-animation-slide-right; repeat: true"
                >
                
                </div>
            </div>
      </div>



      <div className='uk-grid uk-margin-remove-top' data-uk-grid>
           <div className='uk-width-3-5@s'>
                  <div className='forIpadImg'
                      style={{ 
    
                        backgroundImage: ` url(${tiblon1})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                       
                        height:'75vh'
                  }}
                  uk-scrollspy="cls: uk-animation-slide-left; repeat: true"
                  >

                  </div>
           </div>

           <div className='uk-width-2-5@s'>
                      <div className='home_third_pane_comm_right uk-margin-large-top' >
                      <div className='uk-margin-large-bottom'>  <h2 uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Labella Casa</h2>
                         <p style={{color:'#4e4e4e', textAlign:'left'}} uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                         
                         With passion for Luxury and affordable houses, we are delighted to present yet another 
                         breathe taking and luxurious apartment at Guzape Hills, Abuja. La Bella Casa is a 5
                          bedroom luxury property within the Federal Capital Territory.
                         </p>
                         </div>
                         
                         <Link><a href="/projectTwo" className='gold-btn uk-margin-right' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">View  Project</a> </Link>
                      </div>
            </div>
      </div>


      </div>



 {/***MOBILE***/}
 <div className='uk-hidden@s'>
    <div className='uk-container'>
    <div className='home_third_pane'>
                    <h1 uk-scrollspy="cls: uk-animation-slide-down; repeat: true">Latest Communities</h1>
                    <p uk-scrollspy="cls: uk-animation-slide-top; repeat: true">We are delighted to present our latest Communities with 
                    cutting edge technology systems</p>
             </div>
    </div>
           

      <div className='uk-grid uk-grid-collapse uk-margin-large-top'>
            <div className='uk-width-2-5@s'>
                <div className='uk-text-right ' style={{backgroundColor:'#1d4344',height:'60vh'}}>

                  <div className='uk-margin-large-bottom uk-padding'>  <h2 className='light'>Adelaide court</h2>
                    <p>    Each apartment is equipped with contemporary fixtures and fittings 
                    to create a  modern ambience for living.  These apartments Are comprised 
                    of spacious 8 Units of 3 Bedroom Apartments.</p>
                         </div>
                         
                         <Link><a href="/projectOne" className='gold-btn uk-margin-right'>View  Project</a> </Link>
                       
                </div>

               
              

            </div>


            <div className='uk-width-3-5@s'>
                <div 
                
                style={{ 
    
                    backgroundImage: ` url(${index})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                   
                    height:'55vh'
              }}
                
                
                >
                
                </div>
            </div>
      </div>



      <div className='uk-grid uk-margin-remove-top' data-uk-grid>
         

           <div className='uk-width-2-5@s'>
                      <div className='home_third_pane_comm_right uk-margin-large-top' >
                      <div className='uk-margin-large-bottom'>  <h2>Labella Casa</h2>
                         <p style={{color:'#4e4e4e', textAlign:'left'}}> 
                         With passion for Luxury and affordable houses, we are delighted to present yet another 
                         breathe taking and luxurious apartment at Guzape Hills, Abuja. La Bella Casa is a 5
                          bedroom luxury property within the Federal Capital Territory.</p>
                         </div>
                         
                         <Link><a href="/projectTwo" className='gold-btn uk-margin-right'>View  Project</a> </Link>
                      </div>
            </div>



            <div className='uk-width-3-5@s'>
                  <div
                      style={{ 
    
                        backgroundImage: ` url(${tiblon1})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                       
                        height:'55vh'
                  }}
                  
                  >

                  </div>
           </div>
      </div>


      </div>

















    </div>
  )
}

export default Third_Pane