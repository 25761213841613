
import React from 'react'
import project1 from '../../../images/index.jpeg'

import project2 from '../../../images/tiblon1.jpg'

const Seventh_pane = () => {
  return (
    <div className='uk-container'>

        <h2 className='uk-text-center'>Latest communities</h2>

        <div className='uk-grid' data-uk-grid>
             <div className='uk-width-1-2@s' uk-scrollspy="cls: uk-animation-fade; repeat: true">
              <img src= {project1} />
              <h4 className='uk-text-center uk-text-bold'>e</h4>
             </div>

             <div className='uk-width-1-2@s' uk-scrollspy="cls: uk-animation-fade; repeat: true">
             <img src= {project2} />
             
             <h4 className='uk-text-center uk-text-bold'>Labella Casa</h4>
           </div>
        </div>

    </div>
  )
}

export default Seventh_pane