import React from 'react'
import img_Vaud from '../../../images/vaud_img.jpeg'

const Secondpane = () => {
  return (
    <div className='uk-container uk-margin-large-top'>
             <div className='uk-grid' data-uk-grid>
                    <div className='uk-width-1-2@s'>
                        <div className='uk-padding'>
                           <h4 className='uk-text-bold goldText'>OVERVIEW</h4>
                           <p className='uk-text-left' style={{color:'#000'}}>2 Bedroom, Vaud apartment.
Life camp(Dape) behind Berger clinic</p>

                            <p className='uk-text-left uk-margin-top' style={{color:'#000'}}></p>
                        </div>
                    </div>

                    <div className='uk-width-1-2@s'>
                         <img src= {img_Vaud} />
                    </div>
             </div>
    </div>
  )
}

export default Secondpane