import React from 'react'
import exp from '../../../images/exp.JPG'
import check from '../../../images/check.png'



const Second_Pane = () => {
  return (
    <div className='uk-container'>
      <div className='uk-grid uk-margin-large-top' data-uk-grid>
           <div className='uk-width-1-2@s'>
              <img src={exp}  className='exp' uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"/>
           </div>


           <div className='uk-width-1-2@s'>
                <div className='abt-SP-con'>
                    <h2 uk-scrollspy="cls: uk-animation-slide-right; repeat: true">Our Story</h2>
                    <h4 uk-scrollspy="cls: uk-animation-slide-right; repeat: true"> Who we are</h4>
                    <p uk-scrollspy="cls: uk-animation-slide-right; repeat: true">We conduct ourselves in the highest ethical 
                    standards, demonstrating honesty and fairness in every decision and action.</p>

                         <div class="uk-flex uk-margin-top">
                         <img src={check} className='check' />
                           <div uk-scrollspy="cls: uk-animation-slide-right; repeat: true"><p style={{color:'darkgrey', fontSize:'18px'}}>Outstanding property</p></div>
                     </div> 

                     <div class="uk-flex uk-margin-top">
                     <img src={check}  className='check'/>
                           <div uk-scrollspy="cls: uk-animation-slide-right; repeat: true"><p style={{color:'darkgrey', fontSize:'18px'}}>Modern City Locations</p></div>
                     </div> 

                     <div class="uk-flex uk-margin-top">
                        <img src={check}  className='check'/>
                           <div uk-scrollspy="cls: uk-animation-slide-right; repeat: true"><p style={{color:'darkgrey', fontSize:'18px'}}>Market-leading research</p></div>
                     </div> 

                </div>
           </div>
      </div>




    </div>
  )
}

export default Second_Pane