import React from 'react'
import  boite from '../../../images/boite.jpg'


const Fourth_Pane = () => {
  return (
    <div className='container  uk-margin-large-top'>
         <div className='row'>
            <div className='col-sm-5 side-gold-bg uk-padding' >
                  <h4 className='light' uk-scrollspy="cls: .uk-animation-scale-down; repeat: true"> MORE ABOUT US</h4>

                  <h1 className='light' uk-scrollspy="cls: uk-animation-scale-up; repeat: true">15 Years of quality & successful projects</h1>
                  <p className='uk-text-left' uk-scrollspy="cls: uk-animation-scale-down; repeat: true">
                    Established in 1996, Bolek Nigeria Limited is backed up by decade’s worth of insight 
                    and expertise. Over the years, we have successfully carved a niche as the best in
                     building high end infrastructures and delivering unparalleled experiences for our
                      highly esteemed clients for sale and lease.
                        </p>

                       
            </div>

            <div className='col-sm-7   forIpadImg' 
               
            style={{

                backgroundImage: ` url(${boite})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
               
                height:'70vh'
        


            }} >



            </div>
         </div>

       



   

  
    </div>
  )
}

export default Fourth_Pane