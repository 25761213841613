import React from 'react'
import './About.css'
import Layout from '../../Layout/Layout'
import First_Pane from './Blocks/First_Pane'
import Second_Pane from './Blocks/Second_Pane'
import Third_Pane from './Blocks/Third_Pane'
import Fourth_pane from './Blocks/Fourth_pane'

import Fifth_Pane from '../Home/Blocks/Fifth_Pane'
import Sixth_pane from './Blocks/Sixth_pane'
import Seventh_pane from './Blocks/Seventh_pane'

const About = () => {
  return (
    <Layout>
      <First_Pane />
      <Second_Pane />
      <Third_Pane />
      <Fourth_pane />
      <Sixth_pane />
      
      <Fifth_Pane />
      
      <Seventh_pane /> 
     

    </Layout>
    
  )
}

export default About