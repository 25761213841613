import React from 'react'
import projectImg from '../../../images/project_one.jpeg'

const Second_Pane = () => {
  return (
    <div className='uk-container uk-margin-large-top'>
             <div className='uk-grid' data-uk-grid>
                    <div className='uk-width-1-2@s'>
                        <div className='uk-padding'>
                           <h4 className='uk-text-bold goldText'>OVERVIEW</h4>
                           <p className='uk-text-left' style={{color:'#000'}}>Katampe. 3 Bedroom and Bq.</p>

                            <p className='uk-text-left uk-margin-top' style={{color:'#000'}}></p>
                        </div>
                    </div>

                    <div className='uk-width-1-2@s'>
                         <img src= {projectImg} />
                    </div>
             </div>
    </div>
  )
}

export default Second_Pane