import React from 'react'

import { Link } from "react-router-dom";

import heroBg from '../../../images/hero.jpg'

import heroBgSmall from '../../../images/hero.jpg'
import './Hero.css'

const Hero = () => {
  return (
   
    <div>

       {/***DESKTOP VIEW***/}
    <div className="uk-visible@s whyChooseBg "
    
    style={{ 
    
    backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${heroBg})`,
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${heroBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingBottom: '160px',
    backgroundAttachment: 'fixed',
   

}}
       
    >
            
            <div className="uk-container" >
            <div className="uk-grid  " data-uk-grid>
                 <div className="uk-width-1-2@s">
                      <div className="uk-margin-xlarge-top heroHeader " >
                          <h1 className="" uk-scrollspy="cls: uk-animation-slide-left; repeat: true"> Welcome Home</h1>
                          <p className="" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                          Established in 1996, Bolek Nigeria Limited  is backed up by decade’s worth of 
                          insight and expertise. Over the years, we have successfully carved a
                           niche as the best in building high end infrastructures and delivering
                            unparalleled experiences for our highly esteemed clients for sale and lease.</p>
                      </div>

                      <div class="  hero-btn " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                         <Link to="/about">About Us
                           </Link>
                        
                      </div>
  
                 </div>

                 <div className="uk-width-1-2@s">
                     
                 </div>
            </div>
            </div>
        </div>




          {/***MOBILE VIEW***/}
    <div className="uk-hidden@s"
    
    style={{ 
    
      backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${heroBg})`,
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${heroBg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      paddingBottom: '100px',
      height: '80vh',
      paddingTop:'120px'
  
  }}
       
    >
            
            <div className="uk-container " >
            <div className="uk-grid  " data-uk-grid>
                 <div className="uk-width-3-4@s">
                      <div className="uk-margin-xlarge-top " >
                          <h1 className="uk-text-left light withPadding" uk-scrollspy="cls: uk-animation-slide-left; repeat: true"> Welcome Home</h1>
                          <p className="subTitleText uk-text-left" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                            
                            
                          Established in 1996, Bolek Nigeria Limited  is backed up by decade’s worth of 
                          insight and expertise. Over the years, we have successfully carved a
                           niche as the best in building high end infrastructures and delivering
                            unparalleled experiences for our highly esteemed clients for sale and lease.</p>
                      </div>

                      <div class="uk-flex withPadding ">
                      <div class="  hero-btn " uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                         <Link to="/about">About Us
                           </Link>
                        
                      </div>
                      </div>
  
                 </div>

                 <div className="uk-width-1-4@s">
                     
                 </div>
            </div>
            </div>
        </div>





        </div>
  )
}

export default Hero