import React from 'react'

const Fourth_pane = () => {
  return (
    <div>

          <div className='uk-margin-xlarge-top '>

               <div className='uk-container customWidth'>
                <div className='uk-grid' data-uk-grid>
                <div className='uk-width-1-2@s'>
                    <div className='visionBorad' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                        <h2>Vision</h2>
                        <p className='uk-text-small'>To be a leading real estate development and investment company, 
                          providing reliable investment options for all,
                           based on transparency and mutual trust.</p>
                    </div>
                </div>


                <div className='uk-width-1-2@s'>
                <div className='missionBorad' uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                        <h2>Mission</h2>
                        <p className='uk-text-small'>We are committed to achieving our vision by providing affordable,
                           quality and Pristine housing, rendering exceptional real estate 
                           service that will exceed the expectation of all stakeholders. 
                           We measure our success by the results delivered to our clients.</p>
                    </div>
                </div>
                </div>
                        
           </div> </div>

    </div>
  )
}

export default Fourth_pane