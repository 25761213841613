import React from 'react'
import pane_img from '../../../images/pane_img.jpg'

import { Link } from "react-router-dom";

const First_Pane = () => {
  return (
    <div className='container uk-margin-large-top' >
        <div className='row '>
           <div className='col-sm-8' uk-scrollspy="cls: uk-animation-fade; repeat: true">
              <div
                style={{ 
    
                    backgroundImage: ` url(${pane_img})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '380px',
                    height:'auto'
             
                
                }}
              
              
              
              >

                </div>
           </div>

           <div className='col-sm-4' >
               <div className='uk-margin-top home_first_pane'>
                   <h1 style={{color:'#1d4344'}} uk-scrollspy="cls: uk-animation-slide-right; repeat: true">We Build Trust</h1>
                    <p uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                    We aim to not only build structures,but also make living space
                    ecological friendly and smart with modern technology to provide
                    incredible living experience to our happy customers We are poised
                    to explore more avenues of enhancing our services and making 
                    our clients even happier.
                    </p>

                     
                     <div class="  green-btn ">
                         <Link to="/courses">About Us
                           </Link>
                        
                      </div>
               </div>
           </div>
        </div>
    </div>
  )
}

export default First_Pane