import React from 'react'
import slide2 from '../../../images/slide2.JPG'

import { Link } from "react-router-dom";




const Sixth_pane = () => {
  return (
    <div className='uk-margin-xlarge-top'>
         <div className='uk-grid' data-uk-grid>
                <div className='uk-width-2-5@s' >

                </div>


                <div className='uk-width-3-5@s'
                
                style={{ 
    
                  backgroundImage: ` url(${slide2})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  paddingBottom: '150px',
                  height: 'auto'
              
              }}
                
               >

               <div className='uk-grid abt-cust-move uk-padding'uk-scrollspy="cls: uk-animation-slide-left; repeat: true" >

                <div className='uk-width-1-2@s'>
                   <h2 className='uk-margin-top'>Get in touch today</h2>
                   <p>Have any question in mind as regards our communities or general enquiry? </p>


                   <div class="  gold-btn">
                         <Link to="/contact">Contact Us
                           </Link>
                        
                      </div>

                </div>

                <div className='uk-width-1-2@s uk-margin-top'>
                <h4 className='uk-margin-xlarge-top'>Say Hello</h4>
                <h2 style={{marginTop:'-40px'}}>+234 708 338 5164</h2>
                </div>

               </div>

                 





          
                </div>





         </div>


      
    </div>
  )
}

export default Sixth_pane