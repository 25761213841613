import React from 'react'
import screenGrab from '../../../images/screenGrab.JPG'

import Icon1 from '../../../images/icon1.png'
import Icon2 from '../../../images/icon2.png'
import Icon3 from '../../../images/icon3.png'
import Icon4 from '../../../images/icon4.png'





const Third_Pane = () => {
  return (
    <div className=''>
    <div className='uk-margin-large-top '
    
    style={{ 
    
        backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${screenGrab})`,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${screenGrab})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: '100px',
        backgroundAttachment:'fixed',
        
      
    
    }}
 >

                                    <div className='abt-TP uk-margin-large-top'>

                                    <h1 className='uk-text-center light '>WHY CHOOSE US</h1>
                                    <hr />
                                    </div>
  

                                      <div className='uk-container'>

                                    <div className='uk-flex uk-flex-center abt-iconWhole-con' data-uk-flex>
                                         <div className='abt-icon-con  uk-margin-left uk-margin-top' uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true" >
                                         <img src={Icon1 } />
                                                <h4 className='uk-text-bolds'>Affordable Home</h4>
                                                <p>Our properties are located at prime areas which
                                                        guarantees return on  investment..</p>
                                         </div>

                                         <div className='abt-icon-con uk-margin-top uk-margin-left' uk-scrollspy="cls: uk-animation-slide-top-medium; repeat: true">
                                         <img src={Icon2 } />
                                                <h4 className='uk-text-bolds'>High ROI</h4>
                                                <p>We have been in business for over 16 years, for 
                                                    clients in diaspora you can trust us to deliver well finished homes.</p>
                                         </div>

                                         <div className='abt-icon-con uk-margin-top uk-margin-left' uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true" >
                                         <img src={Icon3 } />
                                                <h4 className='uk-text-bolds'>Reliable Partner</h4>
                                                <p>Our homes are very affordable, we have something for both
                                                     the high & middle class earners with flexible payment plans.</p>
                                         </div>

                                         <div className='abt-icon-con uk-margin-top  uk-margin-left' uk-scrollspy="cls: uk-animation-slide-top-medium; repeat: true" >
                                         <img src={Icon4 } />
                                                <h4 className='uk-text-bolds'>Excellence</h4>
                                                <p>Our estates comes with good road network, portable water,
                                                     24hrs light & round the clock security etc.</p>
                                         </div>
                                    </div>
                                    </div>

 

    </div>


    



                        







    </div>
  )
}

export default Third_Pane