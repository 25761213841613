import React from 'react'
import Layout from '../../Layout/Layout'
import First_Pane from './Blocks/First_Pane'

import './ProjectOne.css'
import Second_Pane from './Blocks/Second_Pane'
import Third_pane from './Blocks/Third_pane'
import Fourth_pane from './Blocks/Fourth_pane'
import Fifth_Pane from './Blocks/Fifth_pane'
import Sixth_pane from '../About/Blocks/Sixth_pane'

const ProjectOne = () => {
  return (
       <Layout>
       <First_Pane />
       <Second_Pane />
       <Third_pane />
       <Fourth_pane />
       
       <Fifth_Pane />
       
       <Sixth_pane />
     





       </Layout>
  )
}

export default ProjectOne