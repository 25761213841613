import React from 'react'
import boite from '../../../images/boite_bolek.jpeg'

const Fourth_pane = () => {
  return (
    <div>
        <div className='uk-grid'>
              <div className='uk-width-2-5@s'>
                     <div className='uk-grid uk-padding-small'>
                         <div className='uk-width-1-2 uk-margin-top'>
                            <div className=' uk-padding-small shadow'>
                                <h3 className='uk-text-center'>2 & 3 <br/> Bedrooms</h3>
                            </div>
                         </div>

                         <div className='uk-width-1-2 uk-margin-top '>
                         <div className=' uk-padding-small shadow '>
                                <h3 className='uk-text-center'>2 & 3 <br/> Bedrooms</h3>
                            </div>
                         </div>
                     </div>


                     <div className='uk-grid uk-padding-small'>
                         <div className='uk-width-1-2 '>
                            <div className=' uk-padding-small shadow'>
                                <h3 className='uk-text-center'>2 & 3 <br/> Bedrooms</h3>
                            </div>
                         </div>

                         <div className='uk-width-1-2  '>
                         <div className=' uk-padding-small shadow '>
                                <h3 className='uk-text-center'>2 & 3 <br/> Bedrooms</h3>
                            </div>
                         </div>
                     </div>

                     <div className=' uk-padding-small shadow uk-margin-left uk-margin-right'>
                                <h3 className='uk-text-center'>2 & 3 <br/> Bedrooms</h3>
                            </div>





              </div>

              <div className='uk-width-3-5@s'
              
              style={{ 
    
                backgroundImage: ` url(${boite})`,
                backgroundImage: ` url(${boite})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                paddingBottom: '100px',
                backgroundAttachment:'fixed',
                height: '80vh'
            
            }}
              
               >


</div>
        </div>
    </div>
  )
}

export default Fourth_pane