import React from 'react'
import './Contact.css'
import Layout from '../../Layout/Layout'
import First_Pane from './Blocks/First_Pane'

const Contact = () => {
  return (
    <Layout>
        <First_Pane />


        </Layout>
  )
}

export default Contact