
import React, {useState} from 'react'
import { testimonialsData } from '../../../Data/testimonialsData'
import leftArrow from '../../../images/leftArrow.png'
import rightArrow from '../../../images/rightArrow.png'


const Fifth_Pane = () => {

    const [selected, setSelected] = useState(0);
    const tLength = testimonialsData.length;


    return (
        <div className='container ' style={{backgroundColor:'', paddingTop:'80px', paddingBottom:'70px'}}>
        <div className='Testimonials '>
            <div className='left-t'>
                <span uk-scrollspy="cls: uk-animation-scale-down; repeat: true">Testimonials</span>
                <span className='stroke-text' uk-scrollspy="cls: uk-animation-scale-down; repeat: true">What people</span>
                <span uk-scrollspy="cls: uk-animation-scale-up; repeat: true">Say about us</span>
    
                <span uk-scrollspy="cls: uk-animation-scale-down-medium; repeat: true">
                    {testimonialsData[selected].review}
                </span>
    
                 <span>
                  <span style={{color: 'var(--orange)'}}>
                       {testimonialsData[selected].name}
                  </span> {" "}
                  - {testimonialsData[selected].status}
                  </span>
    
           
            </div>
    
    
    
    
    
    
    
    
            <div className='right-t' uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true">
              <div></div>
              <div></div>
    
    
    
    
                <img src={testimonialsData[selected].image} alt=''/>
    
                <div className='arrows'>
                  
                    <img 
                    onClick={()=>{
                      selected=== 0
                      ? setSelected(tLength -1 )
                      : setSelected((prev)=> prev -1)   
    
                    }}
                    src= {leftArrow} alt =""/>
                     
    
                     <img 
                     onClick= {()=>{
                        selected === tLength -1
                        ? setSelected (0)
                        : setSelected ((prev) => prev + 1 )
                     }}
                     
                      src= {rightArrow} alt =""/>
                </div>
            </div>
        </div>

        </div>
      )
}

export default Fifth_Pane