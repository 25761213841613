import React from 'react'
import about_bg from '../../../images/about_bg.JPG'

const First_Pane = () => {
  return (
    <div
    style={{ 
    
        backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${about_bg})`,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${about_bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: '100px',
        backgroundAttachment:'fixed',
        height: 'auto',
        paddingBottom:'100px'
    
    }}
    
     >
   


          <div className='uk-container '>
           <div className='uk-grid uk-margin-xlarge-top' data-uk-grid>
              <div className='uk-width-1-2@s'>
                 <div className='abt-hero-con' uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                      <hr/>
                      <h1 className=' light'>We Create Your Dream Apartment</h1>
                      <p className=' uk-text-left'>Established in 2006, Bolek Homes is backed up by decade’s 
                        worth of insight and expertise. Over the years, we have 
                        successfully carved a niche as the best in building high 
                        end infrastructures and delivering unparalleled experiences 
                        for our highly esteemed clients for sale and lease.</p>

                    </div>
              </div>

              <div className='uk-width-1-2@s'>
               
              </div>
           </div>
           </div>

    



    </div>
  )
}

export default First_Pane