import React from 'react'
import haj1 from '../../../images/img_bolek1.jpeg'

import haj2 from '../../../images/img_bolek2.jpeg'

import haj3 from '../../../images/img_bolek3.jpeg'

import process1 from '../../../images/process1.jpeg'
import process2 from '../../../images/process2.jpeg'
import process3 from '../../../images/process3.jpeg'
import process4 from '../../../images/process4.jpeg'

import compl7 from '../../../images/compl7.jpg'
import compl6 from '../../../images/compl6.jpg'

import compl4 from '../../../images/compl4.jpg'
import compl5 from '../../../images/compl5.jpg'





const Fifth_pane = () => {
  return (


    <div className='uk-margin-xlarge-top'>
         <h2 className='uk-text-center'>The Promise</h2>
        <div className='uk-container '>
             <div className='uk-grid uk-grid-small' data-uk-grid>
                  <div className='uk-width-1-2@s'
                  
                  style={{ 
    
                    backgroundImage: ` url(${haj1})`,
                  
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '100px',
                    width:'590px',
            
                    height: '50vh'
                
                }}
                  
                  >

                  </div>


                  <div className='uk-width-1-2@s uk-margin-left'
                  
                  style={{ 
    
                    backgroundImage: ` url(${haj3})`,
                  
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '100px',
                    width:'590px',
            
                    height: '50vh'
                
                }}
                  
                  
                  >
                    

                 </div>
             </div>

                   <div className='uk-grid uk-grid-small uk-margin-right'>

                    <div className='uk-width-1-1'
                       style={{ 
                        backgroundImage: ` url(${haj3})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        paddingBottom: '100px',
                      
                        height: '100vh',
                        marginLeft:'10px'
                    
                    }}
                    
                    
                    >
                            
                    </div>

                   </div>

                 
                 <div className='uk-margin-large-top'>
                 <h2 className='uk-text-center'>The Process</h2>


                 <div className='uk-grid uk-grid-small' data-uk-grid>
                  <div className='uk-width-1-2@s'
                  
                  style={{ 
    
                    backgroundImage: ` url(${process1})`,
                  
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '100px',
                    width:'590px',
            
                    height: '50vh'
                
                }}
                  
                  >

                  </div>


                  <div className='uk-width-1-2@s uk-margin-left'
                  
                  style={{ 
    
                    backgroundImage: ` url(${process2})`,
                  
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '100px',
                    width:'590px',
            
                    height: '50vh'
                
                }}
                  
                  
                  >
                    

                 </div>
             </div>



               
             <div className='uk-grid uk-grid-small' data-uk-grid>
                  <div className='uk-width-1-2@s'
                  
                  style={{ 
    
                    backgroundImage: ` url(${process3})`,
                  
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '100px',
                    width:'590px',
            
                    height: '50vh'
                
                }}
                  
                  >

                  </div>


                  <div className='uk-width-1-2@s uk-margin-left'
                  
                  style={{ 
    
                    backgroundImage: ` url(${process4})`,
                  
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '100px',
                    width:'590px',
            
                    height: '50vh'
                
                }}
                  
                  
                  >
                    

                 </div>
             </div>



                 </div>





                 <div className='uk-margin-large-top'>
                 <h2 className='uk-text-center'>Completed</h2>


                 <div className='uk-grid uk-grid-small' data-uk-grid>
                  <div className='uk-width-1-2@s'
                  
                  style={{ 
    
                    backgroundImage: ` url(${compl7})`,
                  
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '100px',
                    width:'590px',
            
                    height: '90vh'
                
                }}
                  
                  >

                  </div>


                  <div className='uk-width-1-2@s uk-margin-left'
                  
                  style={{ 
    
                    backgroundImage: ` url(${compl6})`,
                  
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '100px',
                    width:'590px',
            
                    height: '90vh'
                
                }}
                  
                  
                  >
                    

                 </div>
             </div>



               
             <div className='uk-grid uk-grid-small' data-uk-grid>
                  <div className='uk-width-1-2@s'
                  
                  style={{ 
    
                    backgroundImage: ` url(${compl4})`,
                  
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '100px',
                    width:'590px',
            
                    height: '50vh'
                
                }}
                  
                  >

                  </div>


                  <div className='uk-width-1-2@s uk-margin-left'
                  
                  style={{ 
    
                    backgroundImage: ` url(${compl5})`,
                  
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '100px',
                    width:'590px',
            
                    height: '50vh'
                
                }}
                  
                  
                  >
                    

                 </div>
             </div>



                 </div>


        
        </div>

      
    </div>
  )
}

export default Fifth_pane