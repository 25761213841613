import React from 'react'
import project2 from '../../../images/project2.jpg'

import checks from '../../../images/checks.png'

const First_Pane = () => {
  return (
    <div
    
    style={{ 
    
        backgroundImage: ` url(${project2})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: '100px',
        backgroundAttachment:'fixed',
        height: '110vh'
    
    }}
    >

        <div className='uk-container '>
            <div className='uk-grid' data-uk-grid>
                 <div className='uk-width-1-2@s'>
                    <div className='uk-margin-xlarge-top'>
                        <div className='pjt-fp-bg '>
                            <div className='rule-pjt-fp'></div>
                            <h1>Labella Casa </h1>

                            <div className='uk-flex uk-margin-medium-left'>
                                <div><img src= {checks} width='30px'/> </div>
                                <div className='uk-margin-left'> <h4 className='light'>Air Conditioner</h4> </div>
                            </div>

                            <div className='uk-flex uk-margin-medium-left'>
                                <div><img src= {checks} width='30px'/> </div>
                                <div className='uk-margin-left'> <h4 className='light'>Jacuzzi</h4> </div>
                            </div>

                            <div className='uk-flex uk-margin-medium-left'>
                                <div><img src= {checks} width='30px'/> </div>
                                <div className='uk-margin-left'> <h4 className='light'>Garage</h4> </div>
                            </div>

                            <div className='uk-flex uk-margin-medium-left'>
                                <div><img src= {checks} width='30px'/> </div>
                                <div className='uk-margin-left'> <h4 className='light'>Smart Home</h4> </div>
                            </div>

                            <div className='uk-flex uk-margin-medium-left'>
                                <div><img src= {checks} width='30px'/> </div>
                                <div className='uk-margin-left'> <h4 className='light'>Green Architecture</h4> </div>
                            </div>

                        
                            
                     </div> 











                        </div>
                 </div>


                 <div className='uk-width-1-2@s'>
                    
                 </div>
            </div>

        </div>



    </div>
  )
}

export default First_Pane