import React from 'react'
import paralax_two from '../../../images/paralax_two.JPG'


import new2 from '../../../images/new2.jpg'

import sapphire6 from '../../../images/sapphire6.jpg'






const Fifth_pane = () => {
  return (
    <div className='uk-margin-xlarge-top'>
        <div className='uk-container '>
             <div className='uk-grid uk-grid-small' data-uk-grid>
                  <div className='uk-width-1-2@s'
                  
                  style={{ 
    
                    backgroundImage: ` url(${paralax_two})`,
                  
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '100px',
                    width:'590px',
            
                    height: '50vh'
                
                }}
                  
                  >

                  </div>


                  <div className='uk-width-1-2@s uk-margin-left'
                  
                  style={{ 
    
                    backgroundImage: ` url(${sapphire6})`,
                  
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '100px',
                    width:'590px',
            
                    height: '50vh'
                
                }}
                  
                  
                  >
                    

                 </div>
             </div>

                   <div className='uk-grid uk-grid-small uk-margin-right'>

                    <div className='uk-width-1-1'
                       style={{ 
                        backgroundImage: ` url(${new2})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        paddingBottom: '100px',
                      
                        height: '100vh',
                        marginLeft:'10px'
                    
                    }}
                    
                    
                    >
                            
                    </div>

                   </div>



        
        </div>

      
    </div>
  )
}

export default Fifth_pane