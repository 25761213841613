import React from 'react'
import Vaud3 from '../../../images/vaud_3.jpeg'

const Third_pane = () => {
  return (
    <div
    
    style={{ 
    
        backgroundImage: ` url(${Vaud3})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: '100px',
        backgroundAttachment:'fixed',
        height: '110vh'
    
    }}
    >
        </div>
  )
}

export default Third_pane