import React from 'react'
import Layout from '../../Layout/Layout'
import Firstpane from './Blocks/Firstpane'
import Secondpane from './Blocks/Secondpane'
import Third_pane from './Blocks/Third_pane'
import Sixth_pane from '../About/Blocks/Sixth_pane'

const Vaud = () => {
  return (
    <Layout>
        <Firstpane />
        <Secondpane />
        <Third_pane />
        <Sixth_pane />
   

    </Layout>
  )
}

export default Vaud